import React from 'react'
import Select from 'react-select'
import PropTypes from 'prop-types'

import FieldHeader from '../FieldHeader'

const ReactSelect = ({
  name,
  placeholder,
  setValue,
  defaultValue,
  options,
  label
}) => {
  const handleChange = selectedValue => selectedValue && setValue(selectedValue.value)

  const customStyles = {
    control: () => ({
      width: '100%',
      display: 'flex',
      marginTop: label !== null ? 10 : 0,
    }),
    placeholder: styles => ({ ...styles, color: '#01104B', opacity: 0.2 }),
    indicatorSeparator: () => ({ display: 'none' }),
    valueContainer: styles => ({
      ...styles,
      padding: 0,
      borderBottom: 'solid 1px #E2EDFF80',
    })
  }

  const props = {
    defaultValue,
    styles: customStyles,
    isMulti: false,
    name,
    placeholder,
    value: defaultValue,
    onChange: handleChange,
    options,
    className: 'basic-single'
  }

  return (
    <div>
      {label !== null && <FieldHeader label={label} />}
      <Select {...props} />
    </div>
  )
}

ReactSelect.propTypes = {
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      label: PropTypes.string,
    })
  ).isRequired,
  label: PropTypes.string
}

ReactSelect.defaultProps = {
  label: null
}

export default ReactSelect
