import React from 'react'
import PropTypes from 'prop-types'

import * as s from './style.module.sass'

const RadioButtons = ({ name, values, onChange, defaultValue, small, className }) => (
  <div className={!small ? s.wrapper : ''}>
    <div className={`columns is-multiline ${small ? 'is-variable is-1' : ''} ${className || ''}`}>
      {values.length > 0 &&
        values.map(({ label, value }) => {
          const id = `${name}-${value}`

          return (
            <div key={value} className={`column is-narrow ${s.container}`}>
              <input
                type="radio"
                id={id}
                name={name}
                onClick={() => onChange(value)}
                value={value}
                className={s.input}
                data-testid={`radio-${value}`}
                defaultChecked={defaultValue && value && defaultValue.toString() === value.toString()}
              />
              <label htmlFor={id} className={`${s.labelWrapper} ${small ? s.small : ''}`}>
                <div className={s.labelText}>{label}</div>
              </label>
            </div>
          )
        })}
    </div>
  </div>
)

RadioButtons.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  values: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string,
    })
  ),
  defaultValue: PropTypes.any,
  small: PropTypes.bool,
  className: PropTypes.string,
}

RadioButtons.defaultProps = {
  values: [],
  defaultValue: '',
  onChange: null,
  small: false,
  className: '',
  error: '',
}

export default RadioButtons
