import React from 'react'
import PropTypes from 'prop-types'

import * as s from './style.module.sass'

const FieldHeader = ({ label }) => {
  return (
    <div className={s.titleWrapper}>
      <div className={s.labelWrapper}>
        <label className={s.label}>{label}</label>
      </div>
    </div>
  )
}

FieldHeader.propTypes = {
  label: PropTypes.string
}

FieldHeader.defaultProps = {
  label: ''
}
export default FieldHeader
