import React, { useEffect, useState } from 'react'

import { calculADR, experiences, disciplines, jobs } from '@charrette-club/adr-calculator'

import ReactSelect from '../Forms/ReactSelect'
import RadioButtons from '../Forms/RadioButtons'
import FieldHeader from '../Forms/FieldHeader'

import ContentWrapper from '../ContentWrapper'

import * as s from './style.module.sass'

const JobADR = () => {
  const [TJM, setTJM] = useState(0)
  const [job, setJob] = useState(null)
  const [discipline, setDiscipline] = useState(null)
  const [experience, setExperience] = useState(null)

  useEffect(() => {
    if (experience)
      setTJM(calculADR({job, discipline, experience}))
  }, [job, discipline, experience])

  return (
    <div className={s.wrapper}>
      <div className={s.headerWrapper}>
        <ContentWrapper>
          <h1>Calculateur de TJM</h1>
          <p>Pour rappel, le TJM est le taux journalier moyen, à savoir la rémunération moyenne hors taxes facturée par un indépendant à un client pour une journée de travail.</p>
        </ContentWrapper>
      </div>

      <ContentWrapper className={s.content}>
        <h2 className={s.centerContent}>À vous de jouer</h2>
        <p className={s.centerContent}>Artelink met à disposition son calculateur afin de vous permettre d'estimer votre taux journalier moyen en fonction de votre profil. Les données sont issues d'une grande étude que nous avons réalisé entre le 1er novembre 2020 et le 1er février 2021 auprès de plus de 1100 profils.</p>
        <div className={`${s.calculatorContainer} ${s.centerContent} columns`}>
          <div className={`${s.formContainer} column is-two-fifths-desktop`}>
            <ReactSelect
              name="sector"
              label="Dans quel domaine vous identifiez-vous ?"
              placeholder="Sélectionnez dans la liste"
              setValue={data => setDiscipline(data)}
              options={!!disciplines ? disciplines : []}
              canCreate={true}
            />

            <ReactSelect
              name="job"
              label="Quel est votre métier ?"
              placeholder="Sélectionnez dans la liste"
              setValue={data => setJob(data)}
              options={!!jobs ? jobs : []}
            />

            <div className={s.experienceWrapper}>
              <FieldHeader label={`Quel est votre niveau d'expérience`} />
              <RadioButtons
                name="experienceYearsGaps"
                values={!!experiences ? experiences : []}
                small
                onChange={data => setExperience(data)}
              />
            </div>

          </div>

          <div className='column is-offset-one-fifth-desktop' />

          <div className='column is-two-fifths-desktop'>
            <div className={s.adrContainer}>
              <p className={s.adr}>{ TJM > 0 ? TJM : '-'}€<span> / jour</span></p>
              <p className={s.adrLabel}>TJM indicatif</p>
            </div>
            <p>
              <a href={`${process.env.GATSBY_APP_URL}/signup?pType=FREELANCE`}>Inscrivez-vous sur Artelink</a>
              <br />
              et comparez votre TJM avec des<br />profils similaires au vôtre.
            </p>
          </div>

        </div>
      </ContentWrapper>
    </div>
  )
}

export default JobADR
