import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import JobADR from '../components/JobADR'

const TJMPageTemplate = props => {
  return <JobADR {...props} />
}

TJMPageTemplate.propTypes = {
  title: PropTypes.string
}

const TJMPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark
  const { title } = frontmatter

  return (
    <Layout withoutFooter={true}>
      <Helmet>
        <title>Simulateur Taux Journalier Moyen</title>
        <meta name="description" content="Comment choisir son TJM en freelance ? Aidez-vous de notre simulateur de TJM par métier" />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Simulateur Taux Journalier Moyen" />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Comment choisir son TJM en freelance ? Aidez-vous de notre simulateur de TJM par métier" />
      </Helmet>
      <TJMPageTemplate title={title} />
    </Layout>
  )
}

TJMPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default TJMPage

export const pageQuery = graphql`
  query TJMPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "tjm-page" } }) {
      frontmatter {
        title
      }
    }
  }
`
